<template>
  <td
    class="table-cell"
    :class="classes"
    :style="styles"
    @click="onClick()"
  >
    <Box class="table-cell-inner" :class="{ loading }" :style="innerClasses" flex row alignItems="center">
      <Box v-if="checkbox && cellIndex === 0 && !loading" flex justify="center" mr="2" @click.stop>
        <Checkbox :modelValue="selected" :disabled="checkboxDisabled" @change="emit('select')" />
      </Box>

      <Skeleton v-if="loading" class="table-cell-skeleton" v-bind="skeletonStyle" />

      <Box v-if="$slots[id]" ref="contentRef" class="table-cell-content table-cell-content-box">
        <slot :name="id" v-bind="bindings" />
      </Box>

      <Typography v-else ref="contentRef" class="table-cell-content" v-bind="typographyProps">
        {{ value }}
      </Typography>
    </Box>
  </td>
</template>

<script setup lang="ts" generic="T extends Record<string, unknown>">
import { ComponentProps, KeyOf } from '@lasso/shared/types'
import { computed, nextTick, ref } from 'vue'
import { unrefElement } from '@vueuse/core'

import Typography from '../Typography/Typography.vue'
import Skeleton from '../Skeleton/Skeleton.vue'
import Box from '../Box/Box.vue'
import Checkbox from '../Checkbox/Checkbox.vue'

import { TableColumn, TableSkeleton } from './types'

const props = defineProps<{
  row: Partial<T>
  rowIndex: number
  column: TableColumn<T>
  cellIndex: number
  sticky: boolean
  stickyPosition: number
  hovered: boolean
  loading: boolean
  active: boolean
  checkbox: boolean
  checkboxDisabled: boolean
  selected: boolean
  align: string | number
  skeleton: TableSkeleton
  isTotal: boolean
}>()

const emit = defineEmits<{
  click: []
  select: []
}>()

const value = computed(() => {
  const rawValue = props.row[props.column.id]
  if (rawValue === undefined) {
    return ''
  }

  if (!props.column.modifier) {
    return String(rawValue)
  }

  return props.column.modifier(
    rawValue as Exclude<T[KeyOf<T>], undefined>,
    props.row as T,
    props.isTotal,
  )
})

const id = computed(() => props.column.id)

const classes = computed(() => ({
  [props.align]: true,
  'sticky z-10': props.sticky,
}))

const styles = computed(() => ({
  left: props.sticky ? `${props.stickyPosition}px` : undefined,
}))

const innerClasses = computed(() => ({
  width: props.column.width,
  maxWidth: props.column.maxWidth || props.column.width,
  minWidth: props.column.minWidth || props.column.width,
}))

const typographyProps = computed((): Partial<ComponentProps<typeof Typography>> => ({
  variant: 'body2',
  color: 'textPrimary',
  whiteSpace: props.column.whiteSpace || 'nowrap',
  truncate: Boolean(props.column.maxWidth),
}))

const bindings = computed(() => ({
  row: props.row as T,
  rowIndex: props.rowIndex,
  column: props.column,
  cellIndex: props.cellIndex,
  value: value.value,
  rawValue: props.row[props.column.id] as Exclude<T[KeyOf<T>], undefined>,
  hovered: props.hovered,
  active: props.active,
}))

const skeletonStyle = computed(() => {
  const height = (props.skeleton?.height ?? 4) * 0.25

  return {
    height: `${height}rem`,
    width: 'auto',
  }
})

const contentRef = ref<HTMLTableCellElement>()

const onClick = () => {
  if (!props.column.clickable) {
    return
  }

  emit('click')

  nextTick(() => {
    unrefElement(contentRef)?.querySelector('input')?.focus()
  })
}
</script>

<style scoped>
.table-cell {
  @apply
  rounded-none
  p-0
  h-[54px]; /*height for td works like min-height - table cells will grow when the content does not fit.*/
}

.table-cell-inner {
  @apply w-full px-4 py-1 text-14 tracking-15 relative
}

.table-cell-skeleton {
  @apply absolute left-4 right-4;
}

.table-cell-content-box {
  min-width: 0;
  width: 100%;
}

.table-cell-inner.loading {
  pointer-events: none;
}

.table-cell-inner.loading .table-cell-content {
  visibility: hidden;
}
</style>
