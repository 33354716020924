<template>
  <tr>
    <th
      v-for="(column, index) in tableColumns"
      :id="tableId ? `${tableId}-${column.id}` : undefined"
      :key="column.id"
      class="table-head"
      :class="getColumnClasses(column, index)"
      :style="getColumnStyle(column, index)"
    >
      <Box flex col class="table-head-inner">
        <Box flex row>
          <Box v-if="isFirstCellWithCheckbox(index) && hasData" flex alignItems="center" inlineFlex mr="2">
            <Checkbox
              v-model="allSelected"
              :disabled="Array.isArray(checkboxDisabled) ? checkboxDisabled.length > 0 : checkboxDisabled"
            />
          </Box>
          <Box inlineFlex spaceX="1" alignItems="center" maxWidth="100%">
            <slot v-if="$slots[column.id]" :name="column.id" />
            <Typography v-else-if="column.label" :title="column.label" truncate variant="caption2" color="textSecondaryLight" whiteSpace="nowrap" uppercase>
              {{ column.label }}
            </Typography>

            <Tooltip
              v-if="column.description"
              withCursor
              placement="top"
              :content="column.description"
              tag="div"
              :style="{ display: 'inline-flex' }"
            >
              <Icon icon="info" variant="outlined" color="textSecondaryLight" />
            </Tooltip>

            <ButtonIcon
              v-if="column.sortable"
              :icon="getSortingIconName(column)"
              iconVariant="round"
              variant="text"
              class="sort-button"
              :class="{
                'sort-button-active': column.id === sorting?.sortColumn && sorting?.sortDirection !== 'none',
              }"
              @click="onSort(column)"
            />
          </Box>
        </Box>
        <Box v-if="column.searchable" mt="1">
          <InputText
            :modelValue="column.search"
            :name="column.id"
            placeholder="Search"
            size="sm"
            @update:modelValue="onSearch(column, $event)"
          />
        </Box>

        <Tooltip v-if="column.chip" placement="bottom" :content="column.chip.tooltip" tag="div" class="column-chip">
          <Chip variant="filled" color="secondary" size="2xs">
            {{ column.chip.label }}
          </Chip>
        </Tooltip>
      </Box>
    </th>
  </tr>
</template>

<script setup lang="ts" generic="T extends Record<string, unknown>">
import { useVModel } from '@vueuse/core'
import { KeyOf } from '@lasso/shared/types'
import { StyleValue } from 'vue'

import Box from '../Box/Box.vue'
import ButtonIcon from '../ButtonIcon/ButtonIcon.vue'
import Checkbox from '../Checkbox/Checkbox.vue'
import Typography from '../Typography/Typography.vue'
import InputText from '../Input/InputText/InputText.vue'
import Tooltip from '../Tooltip/Tooltip.vue'
import Icon from '../Icon/Icon.vue'
import { checkForUndefined } from '../../utils'
import { Chip } from '../Chip'

import { TableColumn, TableColumns, TableSorting } from './types'
import { aligns } from './classes'

const props = defineProps<{
  tableId: string
  tableColumns: TableColumns<T>
  sorting?: TableSorting<KeyOf<T>>
  hasData: boolean
  stickyFirstColumn: boolean
  stickyFirstColumns?: number
  columnsOffsetWidths: number[]
  checkbox: boolean
  checkboxDisabled: boolean | number[]
  allSelected: boolean
}>()

const emits = defineEmits<{
  search: [{ column: TableColumn<T>; search: string }]
  sort: [TableColumn<T>]
  'update:allSelected': [boolean]
}>()

function getSortingIconName(column: TableColumn<T>) {
  if (!props.sorting
    || column.id !== props.sorting.sortColumn
    || (column.id === props.sorting.sortColumn && props.sorting.sortDirection === 'none')
  ) {
    return 'unfold_more'
  }

  return props.sorting.sortDirection === 'asc'
    ? 'arrow_upward'
    : 'arrow_downward'
}

const allSelected = useVModel(props, 'allSelected', emits)

const getColumnStyle = (column: TableColumn<T>, index: number): StyleValue => {
  const shouldBeSticky = ((props.stickyFirstColumn && index === 0)
    || (props.stickyFirstColumns && index < props.stickyFirstColumns))
  const stickyPosition = (shouldBeSticky && index !== 0)
    ? props.columnsOffsetWidths.slice(0, index).reduce((acc, width) => acc + width, 0)
    : 0

  return {
    width: column.width,
    maxWidth: column.maxWidth || column.width,
    minWidth: column.minWidth || column.width,
    position: shouldBeSticky ? 'sticky' : undefined,
    left: shouldBeSticky ? `${stickyPosition}px` : undefined,
  }
}

const getColumnClasses = (column: TableColumn<T>, index: number) => {
  const align = checkForUndefined(column.align, aligns)

  const sticky = (props.stickyFirstColumn && index === 0) || (props.stickyFirstColumns && (index < props.stickyFirstColumns))

  return {
    [align]: true,
    // 'static z-0 left-auto' here to fix bug in daysyui
    // https://github.com/saadeghi/daisyui/issues/665
    [sticky ? 'z-10 bg-base-100' : 'static z-0 left-auto']: true,
    'w-px': column.autoWidth,
  }
}

const isFirstCellWithCheckbox = (index: number) => {
  return props.checkbox && index === 0
}

const onSearch = (column: TableColumn<T>, search: string) => {
  emits('search', {
    column,
    search,
  })
}

const onSort = (column: TableColumn<T>) => {
  emits('sort', column)
}
</script>

<style scoped>
.table-head {
  @apply p-0
}

.table-head-inner {
  @apply p-4 pt-0 relative;
}

.sort-button {
  @apply opacity-0
}

.table-head-inner:hover .sort-button,
.sort-button:focus-visible,
.sort-button-active {
  @apply opacity-100;
}

.column-chip {
  position: absolute;
  margin: 0 auto;
  bottom: 0;
  transform: translateY(50%);
  left: 0;
  right: 0;
  text-align: center;
}
</style>
