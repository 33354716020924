export const variants = {
  none: '',
  normal: 'table-normal',
  dense: 'table-compact',
}

export const layouts = {
  auto: 'table-auto',
  fixed: 'table-fixed',
}
