<template>
  <Box inlineFlex :direction="direction" alignItems="center" class="gap-2">
    <slot v-bind="{ id }" />

    <InputLabel
      v-if="label || $slots.label"
      :id="id"
      :required="required"
      :disabled="disabled"
      :class="!disabled ? 'cursor-pointer' : 'cursor-default'"
      :variant="variant"
    >
      <slot name="label">
        {{ label }}
      </slot>
    </InputLabel>
  </Box>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import Box from '../Box/Box.vue'

import InputLabel from '../Input/InputLabel/InputLabel.vue'

import { TypographyVariant } from '../Typography/types'

import type { FieldControlPosition } from './types'

const props = withDefaults(defineProps<{
  position?: FieldControlPosition
  id: string
  label?: string
  required?: boolean
  disabled?: boolean
  variant?: TypographyVariant
}>(), {
  position: 'right',
  label: '',
  required: false,
  disabled: false,
  variant: 'body2',
})

const direction = computed(() => (props.position === 'left' ? 'row-reverse' : 'row'))
</script>
